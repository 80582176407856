<template>
    <div class="flex justify-center pt-28">
        <div class="border p-5">
            <form @submit.prevent="$emit('submit', form)">
                <vs-input class="mb-3" placeholder="Tài khoản" v-model="form.username" />
                <vs-input class="mb-3" placeholder="Mật khẩu" type="password" v-model="form.password" />
                <vs-button type="submit">Đăng nhập</vs-button>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            form: {
                username: '',
                password: ''
            }
        };
    }
}
</script>