<template>
    <form-login @submit="onSubmit($event)" />
</template>
<script>
import FormLogin from '../../originals/forms/FormLogin.vue';

export default {
    components: {FormLogin},
    data(){
        return {};
    },
    methods: {
        onSubmit({username, password}){
            this.$store.dispatch('login', {username, password});
        }
    }
}
</script>