<template>
     <form-login-container />
</template>
<script>
import FormLoginContainer from "../components/containers/forms/FormLoginContainer.vue";
export default {
    components: { FormLoginContainer },
    data() {
        return {
        };
    },
    watch: {
        "$store.getters.isLogged"(value){
            if(value){
                this.$router.push('/admin/notifications/all');
            }
        }
    }
};
</script>